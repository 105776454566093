import React from 'react';
import SectionHeading from '../../SectionHeading';
import Spacing from '../../Spacing';
import IconBoxStyle4 from '../../IconBox/IconBoxStyle4';

export default function DepartmentSectionStyle1({
                                                    sectionTitle,
                                                    sectionTitleUp,
                                                    data,
                                                }) {
    return (
        <div className="container">
            <div className="row">
                <div className="col-md-6 col-xl-4">
                    <SectionHeading title={sectionTitle} titleUp={sectionTitleUp} subTitle={'We are a leading provider of cloud-based Electronic Health Record (EHR) solutions designed to\n' +
                        '                        streamline healthcare operations and improve service delivery. Our mission is to empower\n' +
                        '                        healthcare providers with innovative tools that enhance efficiency, accuracy, and patient\n' +
                        '                        satisfaction'}/>
                    {/*<Spacing md="72" lg="50"/>*/}
                    {/*<p>We are a leading provider of cloud-based Electronic Health Record (EHR) solutions designed to*/}
                    {/*    streamline healthcare operations and improve service delivery. Our mission is to empower*/}
                    {/*    healthcare providers with innovative tools that enhance efficiency, accuracy, and patient*/}
                    {/*    satisfaction.</p>*/}
                </div>
                {data?.map((item, index) => (
                    <div className="col-md-6 col-xl-4" key={index}>
                        <IconBoxStyle4 {...item} />
                    </div>
                ))}
            </div>
        </div>
    );
}
